import React from 'react'
import LightboxLayout from '../../components/lightboxLayout'

const Page = () => {
  return (
    <LightboxLayout>
      <h2>FLUORIDE</h2>
      <h3>What is Fluoride?</h3>
      <p>Fluoride  is a natural mineral  that is very effective  in protecting  teeth against decay.</p>
      <h3>How does Fluoride work?</h3>
      <p>Tooth decay begins with dental plaque, a soft, sticky substance that builds up on your teeth. Plaque is mostly made up of bacteria, which feed on sugar from food and drink, producing acids as a waste product. The acids attack the teeth by dissolving the minerals in the tooth structure. If this happens too often, tooth decay results.</p>
      <p>Minerals in saliva can mend the teeth. If fluoride is present in the mouth, it helps teeth to repair themselves. And if fluoride is consumed in appropriate amounts by young children, it helps to make growing teeth more resistant to decay.</p>
      <p>Fluoride in water supplies</p>
      <p>In a few places, water supplies contain enough fluoride (one part per million) to keep teeth healthy.  This can happen naturally, or the amount of fluoride can be changed to make it right for teeth.  In Britain, only one person in ten gets enough fluoride from the water supply.</p>
      <p>Fluoride in toothpaste</p>
      <p>Most people get flouride from toothpaste. Packs tell you how much the toothpaste contains in parts per million (ppm). If there is fluoride in your local water supply you can still safely use fluoride toothpaste. Adults should use toothpaste containing 1350-1500ppm, unless advised  otherwise by a dentist.</p>
      <p>For children, consuming too much fluoride can produce unattractive marks on their teeth. To prevent this you should help children up to the age of seven clean their teeth. Make sure they do not eat the toothpaste and only use the correct amount:</p>
      <p>Children aged under 3 should only use a smear of toothpaste, which should contain  no less than 1OOOppm of fluoride.</p>
      <p>Children aged 3-6 years should use a small pea-sized blob of toothpaste, containing 1350-1500ppm of fluoride.</p>
      <p>Children may also have fluoride applied as varnish, painted  on by their dentist  or as part of local prevention programmes.</p>
      <p>For patients with a lot of decay  dentists  may recommend extra fluoride, either as tablets, drops  or varnish, and for patients  over 10 years of age toothpastes with 2800ppm fluoride may be prescribed. For patients over 16 with high decay rates 5000ppm toothpaste may be prescribed.</p>
    </LightboxLayout>
  )
}

export default Page